.main {
  display: flex;
  align-items: flex-start;
  padding: 12px 0;
  flex-wrap: wrap;
}

.main > button {
  margin: 8px 24px 8px 0;
}
