@font-face {
  font-family: NotoSerif;
  src: url(./fonts/NotoSerif-Regular.ttf) format("ttf");
  font-weight: 300;
  font-style: normal
}

@font-face {
  font-family: NotoSerif;
  src: url(./fonts/NotoSerif-Bold.ttf) format("ttf");
  font-weight: 600;
  font-style: normal
}

@font-face {
  font-family: Oranda;
  src: url(https://componenten.acc.nijmegen.nl/v6.3.2/oranda_bt.bde3d05f.woff2) format('woff2'),
    url(https://componenten.acc.nijmegen.nl/v6.3.2/oranda_bt.bde3d05f.woff2) format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: Oranda;
  src: url(https://componenten.acc.nijmegen.nl/v6.3.2/oranda_bt.bde3d05f.woff2) format('woff2'),
    url(https://componenten.acc.nijmegen.nl/v6.3.2/oranda_bt.bde3d05f.woff2) format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: Oranda;
  src: url(https://componenten.acc.nijmegen.nl/v6.3.2/oranda_bt.bde3d05f.woff2) format('woff2'),
    url(https://componenten.acc.nijmegen.nl/v6.3.2/oranda_bt.bde3d05f.woff2) format('woff');
  font-weight: 500;
  font-style: normal;
}

*, *:before, *:after {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Oranda', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #343434;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.625rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media (min-width: 48em) {
  body {
    font-size: 1.125rem;
  }
}

h1,h2,h3,h4,h5 {
  font-family:  'Oranda', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
  'Droid Sans', 'Helvetica Neue', sans-serif;
  font-weight: 600;
  font-variant-ligatures: none
}

h1 {
  /* color: #e37900; */
  font-size: 1.8rem;
  line-height: 2.4rem
}

@media (min-width: 48em) {
  h1 {
      font-size:2.5rem;
      line-height: 3.5rem
  }
}

h2 {
  color: #e37900;
  font-size: 1.75rem;
  line-height: 2rem
}

@media (min-width: 48em) {
  h2 {
      font-size:2rem;
      line-height: 2.25rem
  }
}

h3 {
  color: #343434;
  font-size: 1.5rem;
  line-height: 1.75rem
}

@media (min-width: 48em) {
  h3 {
      font-size:1.75rem;
      line-height: 2rem
  }
}

h4 {
  color: #343434;
  font-size: 1.375rem;
  line-height: 1.75rem
}

@media (min-width: 48em) {
  h4 {
      font-size:1.5rem;
      line-height: 1.75rem
  }
}

h5 {
  color: #343434;
  font-size: 1.25rem;
  line-height: 1.5rem
}

@media (min-width: 48em) {
  h5 {
      font-size:1.375rem;
      line-height: 1.75rem
  }
}

a {
  text-decoration: underline;
  font-size: inherit;
  cursor: pointer;
  color: #167c68;
}

a:hover {
  color: #0b6755
}