.main {
  max-width: 1120px;
  margin: 0 auto;
}

.header {
  display: flex;
  padding: 12px;
  justify-content: space-between;
  border-bottom: 1px solid lightgray;
  align-items: center;
}

.headerLeft {
  display: flex;
  align-items: center;
}

.menu > a {
  color: black;
  text-decoration: none;
  padding: 0 12px;
  font-size: 0.9em;
  cursor: pointer;
}

.block {
  max-width: 900px;
  margin: 24px auto;
  padding: 0 12px;
}

@media (min-width: 48em) {
  .block {
    margin: 56px auto;
  }
}

/* .logo {
    width: 36px;
    height: 32px;
    margin-right: 12px;
    background-color: #e37900;
    border-radius: 50%;
    font-size: 0.6em;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
  
  }
  
  .logoText{
    font-style: italic;
    font-size: 1.1em;
    font-weight: 200;
  } */
