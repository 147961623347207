.main{
    position:fixed;
    bottom:0;
    top:0;
    left:0;
    right:0;
    background: url('./images/bg.jpg') no-repeat;
    background-size: cover;
}
.panel{
    margin: 48px auto;
    max-width: 600px;
    background-color: white;
    padding: 24px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.title{
    font-weight: 200;
}
.yiviButton{
    padding: 6px;
    display: flex;
    align-items: center;
    border: 2px solid #3bb977;
    cursor: pointer;
}