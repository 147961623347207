.yiviconnect{
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left:0;  
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10%;
  z-index: 10;
  background-color: white;
}

.irma {
  display: flex;
  font-size: 0.9em;
  flex-direction: column;
  min-width: 480px;
  justify-content: center;
}

@media (max-width: 600px) {
  .irma {
    min-width: inherit;
  }
}

@media (min-width: 48em) {
  .irma {
    flex-direction: row;
  }
}
