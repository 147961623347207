.method{
    width: 32px;
    position: absolute;
    left: 5px;
    top: 4px;
    height: 32px;
    background-color: white;
}
.title{
    font-size: 2.2em;
    line-height: 1.2em;
}