.main{
  position:fixed;
  bottom:0;
  top:0;
  left:0;
  right:0;
  background: url('./images/bg.jpg') no-repeat;
  background-size: cover;
}
.panel{
  margin: 48px auto;
  max-width: 600px;
  background-color: white;
  padding: 24px;
  /* text-align: center; */
  display: flex;
  flex-direction: column;
  /* align-items: center; */
}

.title{
  font-weight: 200;
}

.irma {
  display: flex;
  font-size: 0.9em;
  flex-direction: column;
}

@media (min-width: 480px) {
  .irma {
    flex-direction: row;
  }
}

.helpText{
  padding: 24px;
  width: 50%;
}

@media (max-width: 480px) {
  .helpText{
    display: none;
  }
}
.header{
  font-weight: bold;
}


.downloadButton {
  width: 60%;
  cursor: pointer;
  display: block;
  padding: 12px
}

.downloadButton:hover {
  transform: translateY(-1px);
}

.downloadButton:active,
.downloadButton:hover {
  box-shadow: 0 7px 14px hsla(0, 0%, 74.5%, 0.2), 0 3px 6px hsla(0, 0%, 74.5%, 0.2);
}

.downloadButton:first-child {
  margin: 0 4% 0 0;
}
