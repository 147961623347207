.main {
    max-width: 1000px;
    margin: 0 auto;
  }
  
  .header {
    display: flex;
    padding: 12px;
    justify-content: space-between;
    border-bottom: 1px solid lightgray;
    align-items: center;
  }
  
  .headerLeft {
    display: flex;
    align-items: center;
  
  }
  
  .block {
    max-width: 680px;
    margin: 12px auto;
    padding: 0 12px;
  }
  
  @media (min-width: 48em) {
    .block {
      margin: 24px auto;
    }
  }
  
  .logo {
    width: 36px;
    height: 32px;
    margin-right: 12px;
    background-color: #167c68;
    border-radius: 50%;
    font-size: 0.6em;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
  
  }
  
  .logoText{
    font-style: italic;
    font-size: 1.1em;
    font-weight: 200;
  }
  