.title {
  font-size: 2.2em;
  line-height: 1.2em;
}

.stepContainer {
  padding: 12px 0 24px;
}

.step {
  font-weight: bold;
}

.stepContent {
  padding-left: 13px;
}

.downloadButtonContainer {
  display: flex;
  max-width: 360px;
  padding-top: 28px;
}

.downloadButton {
  width: 48%;
}

.downloadButton:first-child {
  margin: 0 4% 0 0;
}

.downloadButton > img {
  width: 100%;
}

.downloadButton:hover > img {
  transform: translateY(-1px);
}

.downloadButton:active > img,
.downloadButton:hover > img {
  box-shadow: 0 7px 14px hsla(0, 0%, 74.5%, 0.2),
    0 3px 6px hsla(0, 0%, 74.5%, 0.2);
}

.newContainer {
  padding: 24px;
  border: 1px solid lightgray;
  background-color: #f1f1f1;
}

.blockContainer {
  display: flex;
}



.block {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  padding: 12px;
  margin: 12px;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  text-decoration: none;
  color: black;
  box-sizing: border-box;
}

@media only screen and (max-width: 500px) {
  .blockContainer {
    flex-direction: column;
  }
  .block {
   
    width: 100%;

  }
}

.block:hover {
  color: black;
}

.blockTitle {
  color: #a80a2d;
  font-size: 1.4em;
  line-height: 1.2em;
  padding-bottom: 12px;
}
