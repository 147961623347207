.trail{
    font-size: 0.8em;
}
.trail>a{
    color: #167c68;
    cursor: pointer;
    text-decoration: none;
}

.slash{
    padding: 0 12px;
}

.title{
    font-size: 2.2em;
    line-height: 1.2em;
}