.main {
  background-color: #167c68;
  box-shadow: 0 4px 6px hsla(0, 0%, 74.5%, 0.2), 0 1px 3px hsla(0, 0%, 74.5%, 0.2);
  border-radius: 2px;
  border: 0.0625rem solid #167c68;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 15px;
  font-weight: 600;
  line-height: 1rem;
  min-width: 5rem;
  padding: 12px 48px;
  text-decoration: none;
  transition: all 0.15s ease;
  /* white-space: nowrap; */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-transform: uppercase;
  min-width: 60%;
  position: relative;
  max-width: 50%;
}

@media only screen and (max-width: 500px) {
  .main {
    width: 100%;
    max-width: inherit;
  }
}

.main:hover {
  transform: translateY(-1px);
}

.main:active,
.main:hover {
  background-color: #0c6251;
  box-shadow: 0 7px 14px hsla(0, 0%, 74.5%, 0.2), 0 3px 6px hsla(0, 0%, 74.5%, 0.2);
  border-color: #0c6251;
  color: #fff;
}

.white {
  background-color: white;
  color: #167c68;
}

.white:active,
.white:hover {
  box-shadow: 0 7px 14px hsla(0, 0%, 74.5%, 0.2), 0 3px 6px hsla(0, 0%, 74.5%, 0.2);
  border-color: #167c68;
  background-color: white;
  color: #167c68;
}

.whitegreen {
  background-color: white;
  color: #3bb977;
  border-color: #3bb977;
}

.whitegreen:active,
.whitegreen:hover {
  box-shadow: 0 7px 14px hsla(0, 0%, 74.5%, 0.2), 0 3px 6px hsla(0, 0%, 74.5%, 0.2);
  border-color: #3bb977;
  background-color: white;
  color: #3bb977;
}

.green {
  background-color: #3bb977;
  border-color: #3bb977;
  color: black;
}

.green:active,
.green:hover {
  box-shadow: 0 7px 14px hsla(0, 0%, 74.5%, 0.2), 0 3px 6px hsla(0, 0%, 74.5%, 0.2);
  border-color: #3bb977;
  background-color: #3bb977;
  color: black;
}

.disabled {
  background-color: #efefef;
  border-color: #efefef;
  color: #757575;
  cursor: default;
}

.disabled:active,
.disabled:focus,
.disabled:hover {
  transform: translateY(0);
  box-shadow: 0 4px 6px hsla(0, 0%, 74.5%, 0.2), 0 1px 3px hsla(0, 0%, 74.5%, 0.2);
  background-color: #efefef;
  border-color: #efefef;
  color: #757575;
}
